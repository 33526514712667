// Import dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { fullEntryContent } from '../queries'; // eslint-disable-line

// Import Components
import CmsBlocks from '../../../app/components/CmsBlocks';
import LayoutMarketing from '../../../app/components/LayoutMarketing';

const HomePage = ({
  data: {
    craft: { entry, globals },
  },
  ...props
}) => (
  <LayoutMarketing
    hero={entry?.hero && entry?.hero[0]}
    footerLinks={globals?.footer?.footer}
    logo={globals?.general?.logo?.url}
    navItems={globals?.general?.navigationLinks}
    loginUrl={globals?.general?.loginUrl}
    getStartedUrl={globals?.general?.getStartedUrl}
    {...props}
  >
    <CmsBlocks blocks={entry?.contentBlock} />
  </LayoutMarketing>
);

HomePage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export const pageQuery = graphql`
  {
    craft {
      entry(section: [homepage]) {
        ...homeContent
      }
      globals {
        footer {
          footer {
            ...footer
          }
        }
        general {
          logo {
            url
          }
          navigationLinks {
            ...nav
          }
          loginUrl
          getStartedUrl
        }
      }
    }
  }
`;

export default HomePage;
